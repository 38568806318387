/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.color-primary {
  color: var(--ion-color-primary);
}

.exam-mark-option {
  color: var(--ion-color-primary) !important;
}



ion-toast.custom-toast::part(button) {
  border-left: 1px solid #d2d2d2;
  color: var(--ion-color-dark);
  font-size: 15px;
}

ion-toast.custom-toast::part(message) {
  font-size: 18px;
  color: var(--ion-color-dark);
}

ion-toast.custom-toast {
  --background: var(--ion-color-primary);
}

ion-toast.custom-toast-error {
  --background: var(--ion-color-danger);
  --color: var(--ion-color-primary);
}

.action-sheet-group.sc-ion-action-sheet-ios, .action-sheet-button.sc-ion-action-sheet-ios, .action-sheet-button-inner.sc-ion-action-sheet-ios, .action-sheet-button.sc-ion-action-sheet-ios {
  background: var(--ion-color-primary);
  color: var(--ion-color-dark);
}

.action-sheet-cancel.sc-ion-action-sheet-ios::after, .action-sheet-selected.sc-ion-action-sheet-ios::after, .action-sheet-button.ion-activated.sc-ion-action-sheet-ios::after {
  opacity: 0 !important;
}
